body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  /* background-image: linear-gradient(to right top, #06461b, #06461b, #06461b, #06461b, #06461b, #06461b, #06461b, #06461b, #06461b, #06461b, #06461b, #06461b); */
}

body::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Bold";   /*Can be any text*/
  src: local("Zodchiy-Bold 2.0"),
    url("./assets/fonts/Zodchiy-Bold 2.0.ttf") format("woff");
}

@font-face {
  font-family: "Regular";   /*Can be any text*/
  src: local("Sanchez-Regular"),
    url("./assets/fonts/Sanchez-Regular.ttf") format("woff");
}

@font-face {
  font-family: "Normal";   /*Can be any text*/
  src: local("Linotype"),
    url("./assets/fonts/Linotype.ttf") format("woff");
}

@font-face {
  font-family: "futur";   /*Can be any text*/
  src: local("futura_book_font"),
    url("./assets/fonts/futura_book_font.ttf") format("woff");
}
