.btn {
    color: #fff;
    cursor: pointer;
    // display: block;
    font-family: Normal;
    font-size:18px;
    font-weight: 400;
    margin: 0 0 2em;
    max-width: 120px; 
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%; 
    
    
    @media(min-width: 600px) {
        
      margin: 0 0 2em;
          
    }
    
    &:hover { text-decoration: none; }
    
  }
  
.btn-5 {
    border: 0 solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
    outline: 1px solid;
    outline-color: rgba(255, 255, 255, .5);
    outline-offset: 0px;
    text-shadow: none;
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  } 
  
  .btn-5:hover {
    border: 1px solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, .5), 0 0 20px rgba(255, 255, 255, .2);
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 15px;
    text-shadow: 1px 1px 2px #427388; 
  }

  .btnOn {
    color: #000;
    cursor: pointer;
    // display: block;
    font-family: Normal;
    font-size:18px;
    font-weight: 400;
    margin: 0 0 2em;
    max-width: 120px; 
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%; 
    
    
    @media(min-width: 600px) {
        
      margin: 0 0 2em;
          
    }
    
    &:hover { text-decoration: none; }
    
  }
  
.btnOn-5 {
  color: #000;
    border: 0 solid;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0);
    outline: 1px solid;
    outline-color: rgba(0, 0, 0, 0.5);
    outline-offset: 0px;
    text-shadow: none;
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  } 
  
  .btnOn-5:hover {
    color: #000;
    border: 1px solid;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5), 0 0 20px rgba(0, 0, 0, 0.2);
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 15px;
    text-shadow: 1px 1px 2px #000000; 
  }

  .neonText {
    color: #fff;
    text-shadow:
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 42px #0fa,
        0 0 82px #0fa,
        0 0 92px #0fa,
        0 0 102px #0fa,
        0 0 151px #0fa;
  }
