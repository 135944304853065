/* @import url(https://fonts.googleapis.com/css?family=IM+Fell+Double+Pica); */

/* *, *:after, *:before {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  } */

/* html, body {
  height: 100%;
  } */

.Animationbody {
  background-color: hsl(0,60%,50%);
  background-image: 
    linear-gradient(  
      hsla(0,60%,50%,0), 
      hsla(0,60%,50%,.5),  
      hsla(0,60%,50%,.5),
      hsla(0,60%,50%,0)
      ),
    radial-gradient(
      #222,
      black
      );
  background-size: 100% 200%, 100% 100%;
  background-position: 0 -100%, 0 0;
  background-repeat: no-repeat;
  font-family: 'futur';
  animation: 30s weather linear;
  animation-delay: 5s;
  animation-fill-mode: forwards;
  overflow: hidden;
  }
@keyframes weather {  
  0% {
    background-position: 0 -100%, 0 0;    
    }
  100% {
    background-position: 0 50%, 0 0;    
    }
  }

.animationp{
  font-size: 18px;
  top: 0; right: 0;
  bottom: 0; left: 0;
  margin: auto;
  max-width: 80%;
  height: 7em;
  color: rgb(222, 29, 29);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image:
    radial-gradient(
      white,
      transparent 50%
      );
  background-size: 117em;
  background-position: -100% center;
  background-repeat: no-repeat;
  transition: 4s;
  transform-origin: center center;
  transform: scale(.5,.5);
  animation-fill-mode: forwards;
  animation: 8s intro infinite;  
  }
 
.two {
  animation-delay: 4s;
  animation-duration: 10.5s;
  }
.three {
  animation-delay: 10s;
  animation-duration: 10.5s;
  }

@keyframes intro {
  0% {
    background-position: -100% center;
    transform: scale(.5,.5);    
    }
  50% {
    background-position: center center;
    transform: scale(.9,.9);  
    }
  80% {
    background-position: center center;
    transform: scale(1,1);   
    }
  100% {
    background-position: 150% center;
    transform: scale(1,1); 
    }  
  }
@keyframes intro-end {
  0% {
    background-position: -100% center;
    opacity: 0;
    }
  100% {
    background-position: center center;
    opacity: 1;
    }
  }

@keyframes jittery {
  0% {
    transform: 
      translateX(-1px)
      translateY(.6px)
    }
  100% {
    transform: 
      translateX(0px)
      translateY(0px)
    }
  }

  .caption{
    display: flex;
    flex-wrap: wrap;
    line-height: 50px;
    font-size: 30px;
    color: #fff;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-shadow: 0px 5px 5px rgba(0,0,0,.25);
    margin-left: 1px;
    justify-content: center;
    margin-top: 40px;
  }
  
  .text-box{
    display: inline-block;
    position: relative;
    width: 80ch;
    text-wrap: 'wrap';
  }
  
  .text-box div{
    display: inline-block;
    position: absolute;
    top: -200px;
    transform: rotateX(-90deg);
    opacity: 0;
    text-shadow: 0px 5px 5px rgba(0,0,0,.25);
    animation-timing-function: ease;
  }
  
  .text-box div:nth-child(1){
    animation: rollDown 10s forwards infinite;
  }
  
  .text-box div:nth-child(2){
    animation: rollDown2 10s forwards infinite;
  }
  
  /* .text-box div:nth-child(3){
    animation: rollDown3 10s forwards infinite;
  } */
  
  @keyframes rollDown {
    0%{
      top: -200px;
      transform: rotateX(-90deg);
    }
    33%{
      top: -94px;
      transform: rotateX(0deg);
      opacity: 1;
    }
    44%{
      top: -84px;
      transform: rotateX(0deg);
      opacity: 1;
    }
    55%{
      top: 50px;
      transform: rotateX(30deg);
      opacity: 0;
    }
  }
  
  @keyframes rollDown2 {
    55%{
      top: -200px;
      transform: rotateX(-90deg);
    }
    77%{
      top: -74px;
      transform: rotateX(0deg);
      opacity: 1;
    }
    88%{
      top: -74px;
      transform: rotateX(0deg);
      opacity: 1;
    }
    99%{
      top: 50px;
      transform: rotateX(30deg);
      opacity: 0;
    }
  }
  
  /* @keyframes rollDown3 {
    66%{
      top: -200px;
      transform: rotateX(-90deg);
    }
    77%{
      top: -74px;
      transform: rotateX(0deg);
      opacity: 1;
    }
    88%{
      top: -74px;
      transform: rotateX(0deg);
      opacity: 1;
    }
    99%{
      top: 50px;
      transform: rotateX(30deg);
      opacity: 0;
    }
  } */
  
  @keyframes slideColor{
    0%{
      background-color: #387780;
    }
    33%{
      background-color: #3f88c5;
    }
    66%{
      background-color: #588b8b;
    }
    100%{
      background-color: #387780;
    }
  }
