@import url(https://fonts.googleapis.com/css?family=Quattrocento+Sans);

@mixin position-center($text-align: center) {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	text-align: $text-align;
}

.loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	z-index: 1;
}

.loading-text {
	font-size: 70px;
	width: 100%;
	height: 100px;
	line-height: 100px;
	span {
		display: inline-block;
		margin: 0 5px;
		color: #fff;
    font-family: 'Bold';
		@for $i from 0 through 6 {
			&:nth-child(#{$i + 1}) {
				filter: blur(0px);
				animation: blur-text 1.5s (#{$i/5})+s infinite linear alternate;
			}
		}
	}
}

@keyframes blur-text {
	0% {filter: blur(0px);}
	100% {filter: blur(4px);}
}

.fadein-Image{
	animation: fade-image 3s linear forwards;
}

.fadein1-Image1{
	animation: fade-image 3s linear forwards ;
}

.fadeout-Image1{
	animation: fadeout-image 3s linear forwards ;
}
.fadeout1-Image1{
	animation: fadeout-image 3s linear forwards ;
}

@keyframes fade-image {
	0% {opacity: 0}
	50% {opacity: 0.5;}
	100% {opacity: 1;}
}

@keyframes fadeout-image {
	0% {opacity: 1}
	50% {opacity: 0.5;}
	100% {opacity: 0;}
}


// &:checked
      
//       ~ .button

//         box-shadow: 0 15px 25px -4px rgba(0,0,0,0.4), inset 0 -8px 25px -1px rgba(255,255,255,0.9), 0 -10px 15px -1px rgba(255,255,255,0.6), inset 0 8px 20px 0 rgba(0,0,0,0.2), inset 0 0 5px 1px rgba(255,255,255,0.6)

//       ~ .label

//         font-size: 40px
//         color: rgba(0,0,0,0.4)