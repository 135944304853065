.App {
	animation-name: greyScale-reverse;
	animation-duration:2s;
	filter: grayscale(0%) brightness(100%);
  text-align: center;
  background-image: "#06461b";
  background-image: linear-gradient(to right top, #06461b, #06461b, #06461b, #06461b, #06461b, #06461b, #06461b, #06461b, #06461b, #06461b, #06461b, #06461b);
}

.AppGrey {
	animation-name: greyScale;
	animation-duration:4s;
	filter: grayscale(100%) brightness(100%);
  text-align: center;
  background: black;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  width: 960px;
  margin: 0 auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  max-width:100%; 
  height: 1080px;
  resize: initial;
  margin: auto;
  padding: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  background: url('./assets/pageLight1.png') no-repeat center ;
  background-size: cover;
  background-attachment: fixed;
}
.App-header-grey {
	/* filter: grayscale(100%) brightness(100%); */
	background-color: #000000;
	max-width:100%; 
	height: 1080px;
	resize: initial;
	margin: auto;
	padding: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	color: white;
	background: url('./assets/pageLight1.png') no-repeat center ;
	background-size: cover;
	background-attachment: fixed;
  }
.App-header-scroll {
	background-color: #000000;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	/* align-items: center;
	justify-content: center; */
	font-size: 18px;
	color: white;
	animation-name:click-path;
	animation-duration:1.2s;
	/* animation-delay: 0.6s; */
	transition: background-color 1.8s none;
	transition-delay: 1.8s;
	opacity: 0;
	animation-fill-mode: forwards;
  }

.App-header-second {
  background: #06461b;
  width:100%; 
  height:620px;
  /* height:240px;  */
  /* mobile view*/
  /* margin: auto; */
  padding: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(10px + 2vmin);
  color: white;
  /* background:linear-gradient(to right top, #0a2212, #0b2414, #0c2716, #0e2918, #0f2c1a, #102d1b, #102e1c, #112f1d, #112f1d, #11301e, #10301e, #10301f)url('./assets/noisebg2.png'); */
  /* filter: grayscale(100%) brightness(30%); */
  /* animation-name:click-path;
  animation-duration:1.8s;
  animation-delay: 0.6s; */
  /* transition: background-color 3s none;
  transition-delay: 2s; */
}

/* .App-header-second:hover{
  filter: grayscale(100%) brightness(100%);
} */
.grayImageDark{
	filter: grayscale(100%) brightness(30%);
}

.grayImage{
	filter: grayscale(100%) brightness(100%);
}

.grayImage:hover{
	filter: grayscale(0%) brightness(100%);
}

.grayImageDark:hover{
	filter: grayscale(0%) brightness(100%);
}



/* .MuiImageListItem-root{
	color: black;
	filter: grayscale(100%) brightness(100%);
}
.MuiImageListItem-root:hover{
	color: white;
	filter: grayscale(0%) brightness(100%);
                       
} */

.MuiImageListItemBar-title{
	color:white;
	font-family: 'futur';
}
.MuiImageListItemBar-title:hover{
	color:white
}

.secondBlock{
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* background-image:linear-gradient(to right top, #0a2212, #0b2414, #0c2716, #0e2918, #0f2c1a, #102d1b, #102e1c, #112f1d, #112f1d, #11301e, #10301e, #10301f); */
}

.App-link {
  color: #61dafb;
}


@font-face {
  font-family: "Bold";   /*Can be any text*/
  src: local("Zodchiy-Bold 2.0"),
    url("./assets/fonts/Zodchiy-Bold 2.0.ttf") format("woff");
  font-weight: bold;
}

.blink {
	opacity: 1;
  animation: fadeIn 5s linear infinite;
  animation: name duration timing-function delay iteration-count direction fill-mode;
  
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@keyframes fadeIn {
  0%   { opacity:1; }
  25% {opacity: 0.3;}
  50% { opacity:0.3; }
  100% { opacity:1; }
}

.circle {
  z-index: -1;
  position: static;
  min-height:620px;
  max-width:1920px;
  border-radius:0%;
  animation-name:moodColor;
  animation-duration:0.7s;
  background-color:#000000;
  animation-delay:0.8s;
  animation-iteration-count:1;
}
@keyframes moodColor { 
  0% { background-color: #000000;min-height:50px;
    max-width:50px;
    border-radius:50%; }
  10% { background-color: #000000; min-height:200px;
    max-width:200px;
    border-radius:50%; }
  35% { background-color: #000000; min-height:500px;
    max-width:500px;
    border-radius:50%;}
  70% { background-color: #000000; min-height:620px;
    max-width:620px;
    border-radius:50%;}
  100% { background-color: #000000; min-height:720px;
    max-width:1920px;
    border-radius:0%;}
}

.animation{
	background-color:'#06461b';
  /* background:linear-gradient(to right top, #0a2212, #0b2414, #0c2716, #0e2918, #0f2c1a, #102d1b, #102e1c, #112f1d, #112f1d, #11301e, #10301e, #10301f)url('./assets/noisebg2.png'); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  animation-name:click-path-reverse;
  animation-duration:1.8s;
  animation-delay: 0.3s;
  transition: background-color 1.8s none;
  transition-delay: 1.8s;
  opacity: 0;
}

@keyframes click-path {
  0% {background-color: #000000;clip-path: circle(1% at 50% 100%); z-index: -1; opacity: 0; }
  100% { background-color: #000000;clip-path: circle(150% at 50% 100%); z-index: -1; opacity: 1;}
}

@keyframes click-path-reverse {
  0% {background-color: #000000;clip-path: circle(150% at 50% 100%); }
  100% { background-color: #000000;clip-path: circle(1% at 50% 100%);}
}

@keyframes greyScale {
	0% {filter: grayscale(0%) brightness(100%); }
	50% {filter: grayscale(50%) brightness(100%); }
	100% {filter: grayscale(100%) brightness(100%); }
  }

  @keyframes greyScale-reverse {
	0% {filter: grayscale(100%) brightness(100%); }
	50% {filter: grayscale(50%) brightness(100%); }
	100% {filter: grayscale(0%) brightness(100%); }
  }

/* .circle-animation{
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000;
  transition: clip-path 1.2s ease-out;
  clip-path: circle(1% at 50% 100%);
}

.circle-animation.active{
  clip-path: circle(150% at 50% 100%)
} */
promote  {
  font-family: 'futur';
  width: 45ch;
  font-size: 30px;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
  margin-top: 140px;
  opacity: 0%;
  /* position: absolute; */
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: typing 6s steps(26, end) infinite ,
      cursor-blink 0.3s step-end infinite alternate;
}



@keyframes typing {
  20%{opacity: 40%;}
  100% {opacity: 40%;}
  from {
      width: 0;
  }
}

@keyframes cursor-blink {
  50% {
      border-color: transparent;
  }
}
-----------------------------
a {
	text-decoration: none;
  font-size: 30px;
  font-family: Regular;
  justify-content: center;
  align-items: center;
}
h1.main, p.demos {
	-webkit-animation-delay: 18s;
	-moz-animation-delay: 18s;
	-ms-animation-delay: 18s;
	animation-delay: 18s;
}
/**/

@-webkit-keyframes blurFadeInOut {
	0% {
		opacity: 0;
		text-shadow: 0px 0px 40px #fff;
		-webkit-transform: scale(1.3);
	}
	20%, 75% {
		opacity: 1;
		text-shadow: 0px 0px 1px #fff;
		-webkit-transform: scale(1);
	}
	100% {
		opacity: 0;
		text-shadow: 0px 0px 50px #fff;
		-webkit-transform: scale(0);
	}
}
@-webkit-keyframes blurFadeIn {
	0% {
		opacity: 0;
		text-shadow: 0px 0px 40px #fff;
		-webkit-transform: scale(1.3);
	}
	50% {
		opacity: 0.5;
		text-shadow: 0px 0px 10px #fff;
		-webkit-transform: scale(1.1);
	}
	100% {
		opacity: 1;
		text-shadow: 0px 0px 1px #fff;
		-webkit-transform: scale(1);
	}
}
@-webkit-keyframes fadeInBack {
	0% {
		opacity: 0;
		-webkit-transform: scale(0);
	}
	50% {
		opacity: 0.4;
		-webkit-transform: scale(2);
	}
	100% {
		opacity: 0.2;
		-webkit-transform: scale(5);
	}
}
@-webkit-keyframes fadeInRotate {
	0% {
		opacity: 0;
		-webkit-transform: scale(0) rotate(360deg);
	}
	100% {
		opacity: 1;
		-webkit-transform: scale(1) rotate(0deg);
	}
}
/**/

@-moz-keyframes blurFadeInOut {
	0% {
		opacity: 0;
		text-shadow: 0px 0px 40px #fff;
		-moz-transform: scale(1.3);
	}
	20%, 75% {
		opacity: 1;
		text-shadow: 0px 0px 1px #fff;
		-moz-transform: scale(1);
	}
	100% {
		opacity: 0;
		text-shadow: 0px 0px 50px #fff;
		-moz-transform: scale(0);
	}
}
@-moz-keyframes blurFadeIn {
	0% {
		opacity: 0;
		text-shadow: 0px 0px 40px #fff;
		-moz-transform: scale(1.3);
	}
	100% {
		opacity: 1;
		text-shadow: 0px 0px 1px #fff;
		-moz-transform: scale(1);
	}
}
@-moz-keyframes fadeInBack {
	0% {
		opacity: 0;
		-moz-transform: scale(0);
	}
	50% {
		opacity: 0.4;
		-moz-transform: scale(2);
	}
	100% {
		opacity: 0.2;
		-moz-transform: scale(5);
	}
}
@-moz-keyframes fadeInRotate {
	0% {
		opacity: 0;
		-moz-transform: scale(0) rotate(360deg);
	}
	100% {
		opacity: 1;
		-moz-transform: scale(1) rotate(0deg);
	}
}
/**/

@keyframes blurFadeInOut {
	0% {
		opacity: 0;
		text-shadow: 0px 0px 40px #fff;
		transform: scale(1.3);
	}
	20%, 75% {
		opacity: 1;
		text-shadow: 0px 0px 1px #fff;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		text-shadow: 0px 0px 50px #fff;
		transform: scale(0);
	}
}
@keyframes blurFadeIn {
	0% {
		opacity: 0;
		text-shadow: 0px 0px 40px #fff;
		transform: scale(1.3);
	}
	50% {
		opacity: 0.5;
		text-shadow: 0px 0px 10px #fff;
		transform: scale(1.1);
	}
	100% {
		opacity: 1;
		text-shadow: 0px 0px 1px #fff;
		transform: scale(1);
	}
}
@keyframes fadeInBack {
	0% {
		opacity: 0;
		transform: scale(0);
	}
	50% {
		opacity: 0.4;
		transform: scale(2);
	}
	100% {
		opacity: 0.2;
		transform: scale(5);
	}
}
@keyframes fadeInRotate {
	0% {
		opacity: 0;
		transform: scale(0) rotate(360deg);
	}
	100% {
		opacity: 1;
		transform: scale(1) rotate(0deg);
	}
}

.button{
	font-size: 18px;
	font-family: Normal;
	color: white;
	width:140px;
    height:46px;
    border-radius:9px;
	background-image: linear-gradient(to right, #34cf86, #2abf75, #1fb065, #12a155, #009245);
	border-color: white;
	border-width: 2px;
	align-self: flex-start;
}

.button2{
	font-size: 18px;
	font-family: Normal;
	color: white;
	width:140px;
    height:46px;
    border-radius:9px;
	background-image: linear-gradient(to right, #34cf86, #2abf75, #1fb065, #12a155, #009245);
	border-color: white;
	border-width: 2px;
	margin-left: 20px;
	align-self: flex-start;
}

.grid-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 20px;
  }
  
  .grid-item {
	background-color: #eee;
	padding: 20px;
	text-align: center;
  }



.text {
	display: inline-block;
	width: 'auto';
	text-align: left;
	height: 62px;
	vertical-align: -2px;
  }
  
  .text > div {
	font-size:0px;
	opacity: 0;
	/* margin-top:-30px; */
	position:absolute;
  }
  
  .text > div:nth-child(1) {
	animation: roll 10s linear infinite 10s;
  }
  .text > div:nth-child(2) {
	animation: roll 20s linear infinite 15s;
  }
  .text > div:nth-child(3) {
	animation: roll 30s linear infinite 20s;
  }
  .text > div:nth-child(4) {
	animation: roll 40s linear infinite 25s;
  }
  
  @keyframes roll {
	0% {
	  font-size:0px;
	  opacity:0;
	  margin-top:-30px;
	}
	3% {
	  opacity:1;
	  transform: rotate(0deg);
	}
	5% {
	  font-size:inherit;
	  opacity:1;
	  margin-top:0px;
	}
	20% {
	  font-size:inherit;
	  opacity:1;
	  margin-top:0px;
	}
	27% {
	  font-size:0px;
	  opacity: 0.5;
	  margin-top:100px;
	}
	100% {
	  font-size:0px;
	  opacity:0;
	  margin-top:-30px;
	}
  }

  .roller {

	height: 4.125rem;
  
	line-height: 4rem;
  
	position: relative;
  
	overflow: hidden;
  
	width: 100%;
  
	display: flex;
  
	justify-content: center;
  
	align-items: center;
  
	color: #ffffff;
  
  }
  
  #spare-time {
  
	font-size: 1rem;
  
	font-style: italic;
  
	letter-spacing: 1rem;
  
	margin-top: 0;
  
	color: #a8dadc;
  
  }
  
  .roller #rolltext {
  
	position: absolute;
  
	top: 0;
  
	animation: slide 20s infinite;
  
  }
  
  @keyframes slide {
  
	0% {
		opacity: 1;
	  top: 0;
  
	}
  
	25% {
  
	  top: -4rem;
  
	}
  
	50% {
  
	  top: -8rem;
  
	}
  
	/* 72.5% {
  
	  top: -12.25rem;
  
	} */
  }

  .glow {
	color: #fff;
	text-align: center;
	animation: glow 1s ease-in-out none alternate;
  }
  
  @-webkit-keyframes glow {
	from {
	  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
	}
	
	to {
	  text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
	}
  }

  .line {
	width: 100%;
	height: 4rem;
	overflow: hidden;
	border: 1px solid black;
	padding: 0;
	margin-bottom: 16px;
  }

  .lineUp {
	/* font-Size: 120; */
              font-Family: "Bold";
              color:  "#ffffff";
              text-Align: "start";
              margin: "auto";
			  opacity: 0;
	animation: 2s anim-lineUp  linear;
	animation-fill-mode: forwards;
	
  }
  @keyframes anim-lineUp {
	0% {
	  opacity: 0;
	  clip-path: inset(100% 0 100% 0);
	  /* transform: translateY(100%); */
	}
	20% {
	  opacity: 0;
	  clip-path: inset(60% 0 60% 0);
	}
	50% {
	  opacity: 1;
	  /* transform: translateY(0%); */
	  clip-path: inset(20% 0 20% 0);
	}
	100% {
	  opacity: 1;
	  /* transform: translateY(0%); */
	  clip-path: inset(0 0 0 0);
	}
  }

  .linedown {
	/* font-Size: 120; */
              font-Family: "Bold";
              color:  "#ffffff";
              text-Align: "start";
              margin: "auto";
			  opacity: 0;
	animation: 2s anim-linedown  linear;
	animation-fill-mode: forwards;
  }

  @keyframes anim-linedown {
	0% {
	  opacity: 1;
	  /* transform: translateY(0%); */
	  clip-path: inset(0 0 0 0);
	}
	20% {
		opacity: 1;
		/* transform: translateY(0%); */
		clip-path: inset(20% 0 20% 0);
	  }
	  50% {
		opacity: 0;
		clip-path: inset(60% 0 60% 0);
	  }
	  100% {
		opacity: 0;
		clip-path: inset(100% 0 100% 0);
		/* transform: translateY(100%); */
	  }
  }
