.loaderblock {
  width: 100%;
  height: 60px;
//   border-radius: 50%;
//   margin: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  rotate: 360deg;
}

.loader-star {
  position: absolute;
  top: calc(50% - 12px);
}
.loader-17 .css-square { 
  margin-left: 2px;
  
  width: 10px; height: 25px;
background: white;
-webkit-box-shadow: 2px 2px 3px 0px black;
        box-shadow: 2px 2px 3px 0px black;
}


.loader-17 .square1 {
    left: 100px;
    -webkit-animation: dominos 1s 0.125s ease infinite;
    animation: dominos 1s 0.125s ease infinite;
}

.loader-17 .square2 {
    left: 90px;
    -webkit-animation: dominos 1s 0.3s ease infinite;
    animation: dominos 1s 0.3s ease infinite;
}

.loader-17 .square3 {
    left: 80px;
    -webkit-animation: dominos 1s 0.425s ease infinite;
    animation: dominos 1s 0.425s ease infinite;
}

.loader-17 .square4 {
    left: 70px;
    -webkit-animation: dominos 1s 0.540s ease infinite;
    animation: dominos 1s 0.540s ease infinite;
}

.loader-17 .square5 {
    left: 60px;
    -webkit-animation: dominos 1s 0.665s ease infinite;
    animation: dominos 1s 0.665s ease infinite;
}

.loader-17 .square6 {
    left: 50px;
    -webkit-animation: dominos 1s 0.79s ease infinite;
    animation: dominos 1s 0.79s ease infinite;
}

.loader-17 .square7 {
    left: 40px;
    -webkit-animation: dominos 1s 0.9s ease infinite;
    animation: dominos 1s 0.9s ease infinite;
}

.loader-17 .square8 {
    left: 30px;
    -webkit-animation: dominos 1s 1s ease infinite;
    animation: dominos 1s 1s ease infinite;
}
.loader-17 .square9 {
    left: 20px;
    -webkit-animation: dominos 1s 1.2s ease infinite;
    animation: dominos 1s 1.2s ease infinite;
}
.loader-17 .square10 {
    left: 10px;
    -webkit-animation: dominos 1s 1.356s ease infinite;
    animation: dominos 1s 1.356s ease infinite;
}
.loader-17 .square11 {
    left: 0px;
    -webkit-animation: dominos 1s 1.48s ease infinite;
    animation: dominos 1s 1.48s ease infinite;
}
@-webkit-keyframes dominos {
  50% { opacity: 0.7; }
  75% { -webkit-transform: rotate(90deg); transform: rotate(90deg); }
  80% { opacity: 1; } 
 }
    
 @keyframes dominos {
  50% { opacity: 0.7; }
  75% { -webkit-transform: rotate(90deg); transform: rotate(90deg); }
  80% { opacity: 1; } 
 }