.container {
    width: 90%;
    max-width: 960px;
    margin: 30px auto;
    position: relative;
    text-align: center;
}

h1 {
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #F39CC3;
}

nav {
    display: block;
    width: 100%;
}

ul {
   list-style: none; 
   margin-bottom: 20px;
}

nav > ul > li {
    display: inline-block;
}
nav > ul > li > a {
    text-transform: uppercase;
    padding: 4px 10px;
    margin-right: 2px;
    margin-left: 2px;
    text-decoration: none;
    color: #27A4DD;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 25px;
    border: 1px solid #27A4DD;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.hidden {
    display: none;
}

nav > ul > li > a:hover, .current a {
    color: #fff;
    background-color: #27A4DD;
}

#projects > ul > li {
    display: inline-block;
    float: left;
    margin-right: 10px;
    margin-bottom: 5px;
    width: 23%;
    height: auto;
    cursor: pointer;
    border-radius: 5px;
    /* Padding stays within the width */
    box-sizing: border-box;
    position: relative;
    opacity: 0.7;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

#projects > ul > li:hover {
    opacity: 1;
}

img {
    max-width: 100%;
    border-radius: 5px;
}

.gallery {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    padding: 40px 10px;
    display: none;
    box-sizing: border-box;
}

.gallery > img {
    max-height: 100%;
    width: auto;
}

.close i {
    position: fixed;
    top: 10px;
    right: 10px;
    height: 30px;
    width: 30px;
}

.bar {
    display: block;
    position: absolute;
    top: 13px;
    float: left;
    width: 30px;
    border-bottom: 4px solid #fff;
    transform: rotate(45deg);
}

.bar:first-child {
    transform: rotate(-45deg);
}

@media (max-width: 768px){
    #projects > ul > li {
        width: 48%;
    }
}

@media (max-width: 568px) {
    #projects > ul > li {
        width: 100%;
    }
}